<template>
<div :class="'' + ( isMobile ? 'mobile-page-layout' : '')">
    <slot v-if="isMobile">
        <div class="mobile-page-header">
                <div class="mobile-page-header-row">
                    <div class="mobile-page-header-right">
                        <router-link v-if="isMobile" :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                            <i class="header-uil-icon uil uil-angle-right-b"></i>
                        </router-link>
                        <div class="mobile-page-header-icon">
                            <svg class="header-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 512">
                                <path class="fa-primary" d="M279.3 175C271.7 173.9 261.7 170.3 252.9 167.1L248 165.4C235.5 160.1 221.8 167.5 217.4 179.1s2.121 26.2 14.59 30.64l4.655 1.656c8.486 3.061 17.88 6.095 27.39 8.312V232c0 13.25 10.73 24 23.98 24s24-10.75 24-24V221.6c25.27-5.723 42.88-21.85 46.1-45.72c8.688-50.05-38.89-63.66-64.42-70.95L288.4 103.1C262.1 95.64 263.6 92.42 264.3 88.31c1.156-6.766 15.3-10.06 32.21-7.391c4.938 .7813 11.37 2.547 19.65 5.422c12.53 4.281 26.21-2.312 30.52-14.84s-2.309-26.19-14.84-30.53c-7.602-2.627-13.92-4.358-19.82-5.721V24c0-13.25-10.75-24-24-24s-23.98 10.75-23.98 24v10.52C238.8 40.23 221.1 56.25 216.1 80.13C208.4 129.6 256.7 143.8 274.9 149.2l6.498 1.875c31.66 9.062 31.15 11.89 30.34 16.64C310.6 174.5 296.5 177.8 279.3 175z" />
                                <path class="fa-secondary" d="M568.2 336.3c-13.12-17.81-38.14-21.66-55.93-8.469l-119.7 88.17h-120.6c-8.748 0-15.1-7.25-15.1-15.99c0-8.75 7.25-16 15.1-16h78.25c15.1 0 30.75-10.88 33.37-26.62c3.25-20-12.12-37.38-31.62-37.38H191.1c-26.1 0-53.12 9.25-74.12 26.25l-46.5 37.74L15.1 383.1C7.251 383.1 0 391.3 0 400v95.98C0 504.8 7.251 512 15.1 512h346.1c22.03 0 43.92-7.188 61.7-20.27l135.1-99.52C577.5 379.1 581.3 354.1 568.2 336.3z" /> 
                            </svg>
                        </div>
                        <div class="mobile-page-header-text">سبد دارایی</div>  
                    </div>
                    <div class="mobile-page-header-actions">
        
                        <div class="mobile-page-header-menu">
                            <template>
                                <div class="text-center">
                                    <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                            <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                        </div>
                                    </template>
                                    <v-list>
                                        <v-list-item v-for="(item, index) in menus" :key="index"  @click.prevent="get_modal(item.modal)">
                                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                    </v-menu>
                                </div>
                            </template>                 
                        </div>
                    </div>
                </div>
        </div>
        
        <div class="mobile-app-tabs">
            <div :class="'mobile-app-tabs-items ' + (selectedCategory == 1 ? 'app-active-tab' : '')" @click="setActiveCategory(1)">
                <div class="mobile-app-tabs-item">
                    <div class="mobile-app-tabs-item-row">
                        <h3>نمای کلی</h3> 
                    </div>
                </div>
            </div>
            <div :class="'mobile-app-tabs-items ' + (selectedCategory == 2 ? 'app-active-tab' : '')" @click="setActiveCategory(2)">
                <div class="mobile-app-tabs-item">
                    <div class="mobile-app-tabs-item-row">
                        <h3>دارایی‌ها</h3> 
                    </div>
                </div>
            </div>
            <div :class="'mobile-app-tabs-items ' + (selectedCategory == 3 ? 'app-active-tab' : '')" @click="setActiveCategory(3)">
                <div class="mobile-app-tabs-item">
                    <div class="mobile-app-tabs-item-row">
                        <h3>سوابق خرید</h3> 
                    </div>
                </div>
            </div>
            <div :class="'mobile-app-tabs-items ' + (selectedCategory == 4 ? 'app-active-tab' : '')" @click="setActiveCategory(4)">
                <div class="mobile-app-tabs-item">
                    <div class="mobile-app-tabs-item-row">
                        <h3>سوابق فروش</h3> 
                    </div>
                </div>
            </div>
        </div>
    </slot>

    <div v-if="!isMobile" class="dashboard-widget-tabs dashboard-widget-pages">
        <div class="dashboard-widget-tabs-content">
            <h2> 
                <!-- <i class="uil uil-chart"></i> -->
                <svg class="rightnav-svg rightnav-svg-page" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 512">
                    <path class="fa-primary" d="M279.3 175C271.7 173.9 261.7 170.3 252.9 167.1L248 165.4C235.5 160.1 221.8 167.5 217.4 179.1s2.121 26.2 14.59 30.64l4.655 1.656c8.486 3.061 17.88 6.095 27.39 8.312V232c0 13.25 10.73 24 23.98 24s24-10.75 24-24V221.6c25.27-5.723 42.88-21.85 46.1-45.72c8.688-50.05-38.89-63.66-64.42-70.95L288.4 103.1C262.1 95.64 263.6 92.42 264.3 88.31c1.156-6.766 15.3-10.06 32.21-7.391c4.938 .7813 11.37 2.547 19.65 5.422c12.53 4.281 26.21-2.312 30.52-14.84s-2.309-26.19-14.84-30.53c-7.602-2.627-13.92-4.358-19.82-5.721V24c0-13.25-10.75-24-24-24s-23.98 10.75-23.98 24v10.52C238.8 40.23 221.1 56.25 216.1 80.13C208.4 129.6 256.7 143.8 274.9 149.2l6.498 1.875c31.66 9.062 31.15 11.89 30.34 16.64C310.6 174.5 296.5 177.8 279.3 175z" />
                    <path class="fa-secondary" d="M568.2 336.3c-13.12-17.81-38.14-21.66-55.93-8.469l-119.7 88.17h-120.6c-8.748 0-15.1-7.25-15.1-15.99c0-8.75 7.25-16 15.1-16h78.25c15.1 0 30.75-10.88 33.37-26.62c3.25-20-12.12-37.38-31.62-37.38H191.1c-26.1 0-53.12 9.25-74.12 26.25l-46.5 37.74L15.1 383.1C7.251 383.1 0 391.3 0 400v95.98C0 504.8 7.251 512 15.1 512h346.1c22.03 0 43.92-7.188 61.7-20.27l135.1-99.52C577.5 379.1 581.3 354.1 568.2 336.3z" /> </svg>
                <span class="page-title-svg">سبد دارایی</span>
            </h2>
            <!-- <p>
                خلاصه توضیحات کوتاه درباره این بخش از داشبورد شبکه اطلاع رسانی ارز طلا و سکه
            </p> -->
            <div class="dashboard-widget-h-btns">
                <a href="" @click.prevent="open_help_modal()" class="m-left m-top tgju-btn tgju-btn-light tgju-help-btn" v-tooltip="'راهنما'"><i class="uil uil uil-question-circle"></i></a>
            </div>
        </div>
        <div class="widget-tabs">
            <div class="widget-tabs-nav">
                <div class="widget-select menu-tags db flex-grow-1 m-top show-1200">
                    <v-select2
                    class="tgju-v-select-m"
                        v-model="selectedCategory"
                        :reduce="(option) => option.id"
                        :clearable="false"
                        label="label"
                        :options="[
                            { label: 'نمای‌کلی', id: 1 },
                            { label: 'دارایی‌ها', id: 2 },
                            { label: 'سوابق خرید', id: 3 },
                            { label: 'سوابق فروش', id: 4 },
                        ]"
                        @input="setActiveCategory()"
                    />
                </div>
                <router-link :to="{ name: 'Investment' }" class="m-left m-top tgju-btn tgju-btn-light-orange hide-1200">
                    <i class="uil uil-layer-group"></i> نمای‌کلی
                </router-link>
                <router-link :to="{ name: 'InvestmentInventory' }" class="m-left m-top tgju-btn tgju-btn-light-orange hide-1200">
                    <i class="uil uil-wallet"></i> دارایی‌ها
                </router-link>
                <router-link :to="{ name: 'InvestmentBuyHistory' }" class="m-left m-top tgju-btn tgju-btn-light-orange hide-1200">
                    <i class="uil uil-receipt-alt"></i> سوابق خرید
                </router-link>
                <router-link :to="{ name: 'InvestmentSellHistory' }" class="m-left m-top tgju-btn tgju-btn-light-orange active hide-1200">
                    <i class="uil uil-receipt-alt"></i> سوابق فروش
                </router-link>
            </div>
        </div>
    </div>

    <div :class="'' + ( !isMobile ? 'row tgju-widgets-row dashboard-widgets-technical-list dash-full-height' : '')">
        <div :class="'' + ( !isMobile ? 'col-12' : '')">
            <SellHistoryComponent></SellHistoryComponent>
        </div>
    </div>
</div>
</template>

<script>
import Vue from 'vue';
// این ویو مربوط به تاریخچه ی فروش های کاربر در ماژول سرمایه گذاری است
import SellHistoryComponent from '@/components/Investment/SellHistory.vue'
import vSelect2 from 'vue-select';
Vue.component('v-select2', vSelect2);

export default {
    name: 'SellHistory',
    components: {
        SellHistoryComponent
    },
    data: function () {
        return {
            isMobile:false,
            selectedCategory: 4,
            widget: {},
            data: {},
            menus: [
                { title: 'راهنما', modal: 'help' }
            ],
        }
    }, 
    mounted() {
        if(this.$helpers.runHelp('ShowHelp')){
            this.open_help_modal();
        }
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        get_modal(name){
            if(name == 'help'){
                this.open_help_modal();
            }
        },
        setActiveCategory(id) {
            if(id){
                this.selectedCategory = id;
            }
            switch(this.selectedCategory){
                case 1:
                    this.$router.push({name: 'Investment'} );
                    break;
                case 2:
                    this.$router.push({name: 'InvestmentInventory'} );
                    break;
                case 3:
                    this.$router.push({name: 'InvestmentBuyHistory'} );
                    break;
                case 4:
                    this.$router.push({name: 'InvestmentSellHistory'} );
                    break;
            }
        },
        // مودال راهنما
        open_help_modal() {
            let modal_data = {
                modal: 'widget_help_modal',
                component: 'Helps/Investment.vue',
                title: 'سبد دارایی',
                show: true,
            };
            this.$root.$emit('help_modal', modal_data);
        },
    },
}
</script>
