<template>
<div class="tgju-widget-content full-padding">
    <div class="list-widget list-dash-items list-widget-structure">
        <div :class="'tgju-widget light min-h-unset ' + ( isMobile ? 'widget-mobile-clear' : '')">
            <div class="list-widget  list-row bold list-row-title">
                <div class="list-col l-title dot head-color no-border">
                    <span>سوابق فروش</span>
                </div>
                <div class="list-col l-item head-color" v-tooltip="'بروزرسانی'">
                    <i class="uil uil-sync pointer" @click="getHistory()"></i>
                </div>
                <div v-if="!isMobile" class="list-col l-item head-color">
                    <i class="uil uil-shopping-cart-alt"></i>
                </div>
            </div>
            <div class="tgju-widget-content full-padding">
                <slot v-if="widgetLoad">
                    <div class="text-center p-2">
                        <div class="widget-loading"></div>
                    </div>
                </slot>
                <slot v-else>
                    <table v-if="histories.data.length" class="widget-table widget-table-lg">
                        <thead>
                            <tr>
                                <th class="text-center">عنوان شاخص</th>
                                <th class="text-center">تعداد</th>
                                <th class="text-center">ارزش واحد</th>
                                <th class="text-center hide-table-r hide-640">ارزش کل</th>
                                <th class="text-center hide-480">زمان فروش</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="history in histories.data" :key="history.id">
                                <td class="border-l text-center">
                                    {{ history.label }}
                                </td>
                                <td class="border-l text-center">
                                    {{ formatPrice(history.amount) }}
                                </td>
                                <td class="border-l text-center">
                                    {{ formatPrice(history.price) }}
                                </td>
                                <td class="border-l text-center hide-table-r hide-640">
                                    {{ formatPrice(history.total) }}
                                </td>
                                <td class="border-l text-center hide-480">
                                    {{ toRelative(history.created_at) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <slot v-else>
                        <div class="popup-dash-empty">
                            <i class="uil uil-shopping-cart-alt" aria-hidden="true"></i>
                            <span>مقداری برای نمایش وجود ندارد</span>
                        </div>
                    </slot>
                </slot>
            </div>
        </div>
    </div>
    <div v-if="histories.last_page > 1" class="p-5 d-flex justify-content-center">
        <Paginate :page-count="histories.last_page" :click-handler="getHistory" :prev-text="'قبل'" :next-text="'بعد'" :container-class="'pagination'"></Paginate>
    </div>
</div>
</template>

<style lang="scss" scoped>
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
</style>

<script>
// این کامپوننت اجزاری سابقه فروش را در ماژول سرمایه گذاری دارا می باشد
import moment from "moment-jalaali";
import Paginate from "vuejs-paginate";

export default {
    name: 'SellHistoryComponent',
    components: {
        Paginate,
    },
    data: function () {
        return {
            isMobile:false,
            histories: [],
            widgetLoad: true,
        }
    },
    mounted() {
        this.getHistory();
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        // این متد جهت دریافت اطلاعات سوابق فروش از سرور قرار میگیرد
        getHistory(page) {
            this.widgetLoad = true;
            let api_data = {
                page: page ? page : 1,
            };

            // ارسال درخواست ای پی آی
            this.$helpers.makeRequest('POST', '/investment/history/sell', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);
                this.histories = api_response.data.response.history;
                this.widgetLoad = false;
            });
        },
    },
}
</script>
